import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { withTrans } from '../../i18n/withTrans'
import './sitemap.scss'
// import { menuData } from '../../data'
import { useMenu } from '../../hooks/use-menu'
import Group from './group'
import { contactMenuSitemap, paymentMenuSitemap } from '../../data'
import { Seo } from '../../components'

const Sitemap = ({ t }) => {
  const { menu } = useMenu()
  const [menuMobile, setMenuMobile] = useState([])
  useEffect(() => {
    setMenuMobile([...menu, contactMenuSitemap, paymentMenuSitemap])
  }, [menu])

  if (!menu) {
    return null
  }

  return (
    <>
      <Seo title="Sitemap" />
      <div className="container-fluid sitemap-page__container">
        <div className="container-xxl">
          <h1 className="page-title">{t('sitemap.title')}</h1>
          <hr className="hr" />
          {_.map(menuMobile, (item, idx) => (
            <div className="row menu-item" key={_.get(item, 'title') + idx}>
              <div className="row-title">
                <h2>{t(`menu.${_.get(item, 'title')}.title`)}</h2>
              </div>
              <Group
                id={_.get(item, 'id')}
                parentTitle={_.get(item, 'title')}
                data={_.get(item, 'children')}
              />
              <hr className="hr" />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default withTrans(Sitemap)
